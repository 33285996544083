<template>
  <v-row class="d-flex justify-center mt-16 pt-xl-16">
    <v-col cols="10" sm="6" md="3" xl="2">
      <v-card
        class="d-flex justify-center mt-4"
        style="opacity: 0.8"
      >
        <v-img
          style="position:absolute; z-index: 1; margin-top: -60px;"
          height="120"
          width="120"
          src="../../../public/img/login-icon.webp"
        ></v-img>
        <div class="mt-16 pt-4">
          <v-card-title class="d-flex justify-center text-h5">
            Iniciar sesión
          </v-card-title>
          <v-card-text>
            <v-form class="mx-12 mt-4 mb-8" @submit.prevent="login">
              <v-text-field
                v-model.trim="user"
                label="Usuario"
                prepend-icon="fas fa-user"
              ></v-text-field>
              <v-text-field
                v-model.trim="password"
                type="password"
                label="Clave"
                prepend-icon="fas fa-lock"
              ></v-text-field>
              <v-autocomplete
                v-model="empresa"
                item-text="abreviacion"
                :items="optionsEmpresa"
                hide-details
                outlined
                dense
                return-object
                label="Empresa"
              ></v-autocomplete>
              <v-btn
                color="primary"
                class="mt-4"
                type="submit"
                :loading="load"
                block
              >
                Ingresar
              </v-btn>
            </v-form>
            <v-expand-transition>
              <v-alert
                v-if="error"
                type="error"
                dense
              >
                {{ message }}
              </v-alert>
            </v-expand-transition>
            <v-footer
              class="d-flex justify-center primary"
              style="opacity: 0.8"
              dark
              app
            >
              <span>
                &copy; {{new Date().getFullYear()}}
                <v-hover v-slot="{ hover }">
                  <a
                    class="white--text"
                    :class="hover ? '' : 'text-decoration-none'"
                    href="https://www.borigenbetzel.com.ar"
                    target="_blank"
                  >
                    Borigen Betzel
                  </a>
                </v-hover>
              </span>
            </v-footer>
          </v-card-text>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import router from '../../router'
export default {
  data() {
    return {
      user: '',
      password: '',
      empresa: {},
      optionsEmpresa: [],
      showpass: false,
      load: false,
      message: '',
      error: false
    }
  },
  created () {
    this.getEmpresas()
  },
  computed: {
    ...mapState(['respEmpresas', 'respUsuario'])
  },
  methods: {
    ...mapActions(['getFirebaseEmpresas', 'loginFirebase']),
    async getEmpresas(){
      this.$store.state.modalCarga = true
      await this.getFirebaseEmpresas()
    },
    async login(){
      this.error = false
      if (this.user.toString().length == 0 || this.password.toString().length == 0 || Object.keys(this.empresa).length == 0){
        this.error = true
        this.message = 'Por favor ingrese todos los campos para poder iniciar sesión.'
        return
      }
      this.load = true
      //window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content
      await this.loginFirebase({user: this.user, password: this.password, empresa: this.empresa.empresa_codigo})
    },
    checkDeslogueo(){
      if (this.$route.hasOwnProperty('params')){
        if (this.$route.params.hasOwnProperty('sesionExpirada')){
          if (parseInt(this.$route.params.sesionExpirada) === 1){
            this.$store.state.msjSnackBar = 'Su sesión expiró. Por favor, vuelva a autenticarse.'
            this.$store.state.colorSnackBar = 'info'
            this.$store.state.snackBarActivo = true
          }
        }
        if (this.$route.params.hasOwnProperty('servicioOnline')){
          if (parseInt(this.$route.params.servicioOnline) === 0){
            this.$store.state.msjSnackBar = 'El servicio se encuentra con algunos problemas. Por favor, intente nuevamente en algunos minutos.'
            this.$store.state.colorSnackBar = 'info'
            this.$store.state.snackBarActivo = true
          }
        }
      }
    }
  },
  watch: {
    respEmpresas: function(){
      if (Object.keys(this.respEmpresas).length > 0){
        this.$store.state.modalCarga = false
        if (this.respEmpresas.resultado == 1){
          this.optionsEmpresa = this.respEmpresas.empresas
        }else{
          this.$swal.fire({
            title: 'Obtener Empresas',
            text: 'No se pudo obtener las empresas disponibles.',
            icon: 'error'
          })
        }
        this.checkDeslogueo()
      }
    },
    respUsuario: function(){
      this.load = false
        if (this.respUsuario.resultado == 1){
          router.push({name: 'Inicio'})
        }else if (this.respUsuario.resultado == 2 || this.respUsuario.resultado == undefined){
          this.error = true
          this.message = 'El usuario no está habilitado en la Empresa seleccionada.'
        }else{
          this.error = true
          this.message = 'El usuario y/o contraseña son incorrectos, por favor reintente nuevamente.'
        }
    }
  },
}
</script>

<style>

</style>